<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="formInline"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="参数名称">
            <template slot-scope="scope">{{
              scope.row.paramName
            }}</template></el-table-column
          >
          <el-table-column label="设定值">
            <template slot-scope="scope">
              <div v-if="scope.row.values.length == 1">
                <el-input
                  v-model="scope.row.realValue"
                  placeholder="设定值"
                ></el-input>
              </div>
              <div v-if="scope.row.values.length > 1">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="scope.row.realValue"
                  placeholder="请选择房间状态"
                >
                  <el-option
                    v-for="item in scope.row.values"
                    :key="item.valueCode"
                    :label="item.valueDesc"
                    :value="item.valueCode"
                  >
                  </el-option>
                </el-select>
              </div> </template
          ></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="hide" class="setFalse">取 消</el-button>
          <el-button type="primary" @click="setSure">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      row: {},
      //表单
      formInline: [],
      //表单验证
      rules: {},
      stindex: -1,
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
    },
    show(state, row) {
      console.log(row);
      this.row = row;
      this.title = state;
      this.showing = true;
      this.formInline = [];
      this.getParamListByControl(row);
    },
    getParamListByControl(row) {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        taskId: row.id,
      };
      getRequest("taskParamList", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.formInline = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    setSure() {
      // let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      this.formInline.forEach((item) => {
        if (item.values.length == 1) {
          item.valueCode = item.realValue;
          item.valueDesc = item.values[0].valueDesc;
        }
        if (item.values.length > 1) {
          item.values.forEach((item2) => {
            if (item.realValue == item2.valueCode) {
              item.valueCode = item2.valueCode;
              item.valueDesc = item2.valueDesc;
            }
          });
        }
      });
      let parms = {
        id: this.row.id,
        paramList: this.formInline,
      };
      this.row;
      postRequest("updateScheduleTaskControl", parms).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "修改成功 ！",
            center: true,
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > textarea {
  width: 100%;
  height: 170px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  padding-top: 10px;
}
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.25;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.setFalse {
  color: #387dff;
  background: #d7e4fc;
  margin-right: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}
</style>
<template>
  <div>
    <el-dialog
      :title="title + '定时任务配置'"
      :visible.sync="showing"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <div>
          <!-- 一个表单 -->
          <el-form
            ref="formInline"
            :model="formInline"
            @submit.native.prevent
            :rules="rules"
          >
            <div class="banben">
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="房间" prop="roomId">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.roomId"
                    placeholder="请选择房间"
                    @change="roomChange"
                  >
                    <el-option
                      v-for="item in roomList"
                      :key="item.id"
                      :label="item.roomNo"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="房间设备" prop="deviceId">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.deviceId"
                    :disabled="roomMange"
                    filterable
                    clearable
                    placeholder="请选择房间设备"
                    @change="mangeChange"
                  >
                    <el-option
                      v-for="item in roomMangeList"
                      :key="item.id"
                      :label="item.deviceTypeName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="banben">
              <!--  -->
              <div class="banben">
                <div class="bandanItem" style="width: 45%">
                  <el-form-item label="任务类型" prop="scheduleType">
                    <el-radio-group v-model="formInline.scheduleType">
                      <el-radio :label="0">单次</el-radio>
                      <el-radio :label="1">周期</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="执行条件" prop="runCondition">
                  <el-radio-group v-model="formInline.runCondition">
                    <el-radio :label="1">无人</el-radio>
                    <el-radio :label="2">有人</el-radio>
                    <el-radio :label="3">全天候</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
            <div class="banben">
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="运行时长(min)">
                  <el-input
                    ref="runTime"
                    v-model="formInline.runTime"
                    placeholder="请输入运行时长"
                    :disabled="formInline.scheduleType != 1"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="启动间隔时间(min)">
                  <el-input
                    ref="startInterval"
                    v-model="formInline.startInterval"
                    placeholder="请输入启动间隔时间"
                    :disabled="formInline.scheduleType != 1"
                  ></el-input>
                </el-form-item>
              </div>
            </div>

            <div class="banben" style="border-bottom: 1px solid #d8d8d8">
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="定时时间">
                  <el-time-picker
                    v-model="formInline.startTime"
                    ref="startTime"
                    format="HH:mm:ss"
                    value-format="HH:mm:ss"
                    style="width: 100%"
                    placeholder="选择时间"
                    :disabled="formInline.scheduleType != 0"
                  >
                  </el-time-picker>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <!-- <el-form :model="csformInline"> </el-form> -->
          <div v-for="(item, index) in csformInline" :key="index">
            <div class="shuzhi" v-if="item.values.length == 1">
              <div class="shuzhi1">{{ item.paramName }}:</div>
              <div class="shuzhi2">
                <el-input
                  v-model="item.valueCode"
                  placeholder="设定值"
                ></el-input>
              </div>
            </div>
            <div class="shuzhi" v-else>
              <div class="shuzhi1">{{ item.paramName }}:</div>
              <div class="shuzhi2">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="item.valueCode"
                  placeholder="请选择房间状态"
                >
                  <el-option
                    v-for="item1 in item.values"
                    :key="item1.valueCode"
                    :label="item1.valueDesc"
                    :value="item1.valueCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      //表单
      formInline: {
        deviceId: null,
        scheduleType: 0,
      },
      csformInline: [],
      roomList: [],
      roomMange: true,
      roomMangeList: [],
      deviceNameList: [],
      parms: {},
      //表单验证
      rules: {
        roomId: [
          {
            required: true,
            message: "请选择房间",
            trigger: "blur",
          },
        ],
        deviceId: [
          {
            required: true,
            message: "请选择房间设备",
            trigger: "blur",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择启动时间",
            trigger: "blur",
          },
        ],
        startInterval: [
          {
            required: true,
            message: "请输入启动间隔时间",
            trigger: "blur",
          },
        ],
        runTime: [
          {
            required: true,
            message: "请输入运行时长",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, index) {
      this.formInline = {
        deviceId: null,
        scheduleType: 0,
        runCondition: 1,
      };
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getRoomList();
      this.csformInline = [];
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
    },
    // 获取房间
    getRoomList() {
      let parms = {
        offset: 1,
        num: 1000,
      };
      postRequest("selectAllHotelRoom", parms).then((res) => {
        if (res.status == 200) {
          this.roomList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 设备id
    roomChange(e) {
      this.roomDevice(e);
      this.roomMange = false;
    },
    roomDevice(e) {
      this.formInline.deviceId = null;
      if (!this.formInline.roomId) {
        return;
      }
      let parms = {
        offset: 1,
        num: 10000,
        roomId: e,
      };
      postRequest("selectAllHotelRoomDevice", parms).then((res) => {
        if (res.status == 200) {
          this.roomMangeList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    mangeChange(id) {
      let deviceType = null;
      this.roomMangeList.forEach((item) => {
        if (item.id == id) {
          deviceType = item.deviceType;
        }
      });
      let parms = {
        deviceType: deviceType,
      };
      postRequest("/getSysDeviceParams", parms).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.csformInline = res.data;
        }
      });
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceNameList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      this.csformInline.forEach((item) => {
        item.roomId = this.formInline.roomId;
      });
      this.formInline.paramList = this.csformInline;
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            if (this.formInline.scheduleType == 0) {
              console.log("单次");
              if (!regMatch(this.formInline.startTime, "启动时间")) {
                this.$refs.startTime.focus();
                return;
              }
            } else {
              if (!regMatch(this.formInline.runTime, "间隔时间")) {
                this.$refs.runTime.focus();
                return;
              }
              if (!regMatch(this.formInline.startInterval, "间隔时间")) {
                this.$refs.startInterval.focus();
                return;
              }
            }
            postRequest("/insertScheduleTaskControl", this.formInline).then(
              (res) => {
                if (res.status == 200) {
                  this.$message({
                    duration: 5000,
                    message: "创建成功!",
                    type: "success",
                  });
                  this.hide();
                  this.$refs.formInline.resetFields(); //清空表单
                  this.$parent.getFounderList();
                } else {
                  this.$message({
                    duration: 5000,
                    message: res.message,
                    type: "warning",
                  });
                }
              }
            );
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      console.log(this.formInline);
      // return
      postRequest("/updateScheduleTaskControl", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },

    changeDeviceType(val) {
      console.log(121, val);
      this.deviceTypeList.forEach((item) => {
        if (item.code == val) {
          console.log(item);
          this.parms.deviceType = item.code;
          this.parms.deviceTypeCode = item.typeCode;
          this.parms.deviceTypeName = item.typeName;
        }
      });
      let parms = {
        deviceType: val,
      };
      postRequest("/getSysDeviceParams", parms).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.csformInline = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
>>> .el-radio-group {
  display: flex;
}
.shuzhi {
  display: flex;
  margin: 20px 0;
  width: 80%;
}
.shuzhi1 {
  width: 20%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.shuzhi2 {
  width: 80%;
}
>>> .el-radio-group {
  margin-top: 50px;
}
>>> .el-form-item__label {
  width: 175px;
  text-align: left;
}
</style>